
import { defineComponent, ref } from 'vue';
import { Field } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';
import { VueCookieNext } from 'vue-cookie-next';

import axios from 'axios';
import { ElNotification } from 'element-plus';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'enrollment summary',
  components: {
    Field,
    Datatable,
  },
  data() {
    return {
      api_url: '',
      trainee: {
        entity_id: '',
        training_institute_id: '',
        tranche_id: '',
      },
      allinstituteSummary: [
        {
          enroll_trainee: '',
          enroll_female_trainee: '',
          assessed_trainee: '',
          assessed_female_trainee: '',
          certification_trainee: '',
          certification_female_trainee: '',
          employment_trainee: '',
          employment_female_trainee: '',
        },
      ],

      allBatchSummary: [
        {
          enroll_trainee: '',
          enroll_female_trainee: '',
          assessed_trainee: '',
          assessed_female_trainee: '',
          certification_trainee: '',
          certification_female_trainee: '',
          employment_trainee: '',
          employment_female_trainee: '',
        },
      ],

      institutekey: 0,
      batchkey: 0,
      tolatEnroll: 0,
      tolatEnrollFemale: 0,
      totalAssessed: 0,
      totalAssessedFemale: 0,
      totalCertification: 0,
      totalCertificationFemale: 0,
      totalEmployment: 0,
      totalEmploymentFemale: 0,
      batchTolatEnroll: 0,
      batchTolatEnrollFemale: 0,
      batchTotalAssessed: 0,
      batchTotalAssessedFemale: 0,
      batchTotalCertification: 0,
      batchTotalCertificationFemale: 0,
      batchTotalEmployment: 0,
      batchTotalEmploymentFemale: 0,
      associations: [],
      institutes: [],
      tranches: [],
      instituteSummary: [],
      batchSummary: [],
      notenrollTraineeList: [],
      notEnComponentKey: 0,
      enComponentKey: 0,
      load: false,
      loading: false,
      tabIndex: ref(0),
      btnCheck: false,
    };
  },
  async created() {
    await this.associationList();
    await this.getTranches();
    this.emitter.on('infos-updated', async () => {});
  },
  methods: {
    async downloadPdfD() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      let formData = new FormData();
      formData.set('entity_id', entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('institute_info_id', institute_info_id);
      await ApiService.post('enrollment/batch_course_enrollment_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async printPdfD() {
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      let formData = new FormData();
      formData.set('entity_id', entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('institute_info_id', institute_info_id);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/enrollment/batch_course_enrollment_print`,
          formData
        )
        .then((response) => {
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it
        });
    },

    async exportTraineeD() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      let formData = new FormData();
      formData.set('entity_id', entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('institute_info_id', institute_info_id);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/enrollment/batch_course_enrollment_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'institute-enrollment-report.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      let formData = new FormData();
      formData.set('entity_id', entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('institute_info_id', institute_info_id);
      await ApiService.post('enrollment/institute_enrollment_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async printPdf() {
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      let formData = new FormData();
      formData.set('entity_id', entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('institute_info_id', institute_info_id);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/enrollment/institute_enrollment_print`,
          formData
        )
        .then((response) => {
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it
        });
    },

    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      let formData = new FormData();
      formData.set('entity_id', entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('institute_info_id', institute_info_id);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/enrollment/institute_enrollment_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'institute-enrollment-report.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },

    async associationList() {
      this.load = true;
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.associations = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async trainingInstitute() {
      this.load = true;
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('institute/list?entity_id=' + entity_id)
        .then((response) => {
          this.institutes = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranches() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getInstituteSummary() {
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      if (entity_id) {
        this.load = true;
        await ApiService.get(
          'enrollment/institute_summary?entity_id=' +
            entity_id +
            '&tranche_id=' +
            this.trainee.tranche_id +
            '&institute_info_id=' +
            institute_info_id
        )
          .then((response) => {
            this.instituteSummary = response.data.data;
            this.allinstituteSummary = response.data.data;
            // sum
            const sum = this.allinstituteSummary.reduce(
              (acc, cur) => {
                acc.enroll_trainee += +cur.enroll_trainee;
                acc.enroll_female_trainee += +cur.enroll_female_trainee;
                acc.assessed_trainee += +cur.assessed_trainee;
                acc.assessed_female_trainee += +cur.assessed_female_trainee;
                acc.certification_trainee += +cur.certification_trainee;
                acc.certification_female_trainee +=
                  +cur.certification_female_trainee;
                acc.employment_trainee += +cur.employment_trainee;
                acc.employment_female_trainee += +cur.employment_female_trainee;
                return acc;
              },
              {
                enroll_trainee: 0,
                enroll_female_trainee: 0,
                assessed_trainee: 0,
                assessed_female_trainee: 0,
                certification_trainee: 0,
                certification_female_trainee: 0,
                employment_trainee: 0,
                employment_female_trainee: 0,
              }
            );

            this.tolatEnroll = sum.enroll_trainee;
            this.tolatEnrollFemale = sum.enroll_female_trainee;
            this.totalAssessed = sum.assessed_trainee;
            this.totalAssessedFemale = sum.assessed_female_trainee;
            this.totalCertification = sum.certification_trainee;
            this.totalCertificationFemale = sum.certification_female_trainee;
            this.totalEmployment = sum.employment_trainee;
            this.totalEmploymentFemale = sum.employment_female_trainee;

            // this.allinstituteSummary.forEach((el) => {
            //   this.tolatEnroll += ~~el.enroll_trainee;
            //   this.tolatEnrollFemale += ~~el.enroll_female_trainee;
            //   this.totalAssessed = ~~el.assessed_trainee;
            //   this.totalAssessedFemale = ~~el.assessed_female_trainee;
            //   this.totalCertification = ~~el.certification_trainee;
            //   this.totalCertificationFemale = ~~el.certification_female_trainee;

            //   this.totalEmployment = ~~el.employment_trainee;
            //   this.totalEmploymentFemale = ~~el.employment_female_trainee;
            // });
            this.institutekey += 1;
            this.load = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        this.tabIndex = 0;
        Swal.fire({
          title: 'Error!',
          html: 'Please select training partner.',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },

    async getBatchSummary() {
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      if (institute_info_id) {
        this.load = true;
        await ApiService.get(
          'enrollment/batch_summary?entity_id=' +
            entity_id +
            '&tranche_id=' +
            this.trainee.tranche_id +
            '&institute_info_id=' +
            institute_info_id
        )
          .then((response) => {
            this.batchSummary = response.data.data;
            this.allBatchSummary = response.data.data;

            const sum = this.allBatchSummary.reduce(
              (acc, cur) => {
                acc.enroll_trainee += +cur.enroll_trainee;
                acc.enroll_female_trainee += +cur.enroll_female_trainee;
                acc.assessed_trainee += +cur.assessed_trainee;
                acc.assessed_female_trainee += +cur.assessed_female_trainee;
                acc.certification_trainee += +cur.certification_trainee;
                acc.certification_female_trainee +=
                  +cur.certification_female_trainee;
                acc.employment_trainee += +cur.employment_trainee;
                acc.employment_female_trainee += +cur.employment_female_trainee;
                return acc;
              },
              {
                enroll_trainee: 0,
                enroll_female_trainee: 0,
                assessed_trainee: 0,
                assessed_female_trainee: 0,
                certification_trainee: 0,
                certification_female_trainee: 0,
                employment_trainee: 0,
                employment_female_trainee: 0,
              }
            );

            this.batchTolatEnroll = sum.enroll_trainee;
            this.batchTolatEnrollFemale = sum.enroll_female_trainee;
            this.batchTotalAssessed = sum.assessed_trainee;
            this.batchTotalAssessedFemale = sum.assessed_female_trainee;
            this.batchTotalCertification = sum.certification_trainee;
            this.batchTotalCertificationFemale =
              sum.certification_female_trainee;
            this.batchTotalEmployment = sum.employment_trainee;
            this.batchTotalEmploymentFemale = sum.employment_female_trainee;

            this.batchkey += 1;
            this.load = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        this.tabIndex = 0;
        Swal.fire({
          title: 'Error!',
          html: 'Please select training institute.',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    setActiveTab(event) {
      this.tabIndex = parseInt(event.target.getAttribute('data-tab-index'));

      if (this.tabIndex == 1) {
        this.getInstituteSummary();
      }
      if (this.tabIndex == 2) {
        this.getBatchSummary();
      }
    },
  },
});
